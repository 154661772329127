import { BlocksContentProp } from "@lib/data/_types"
import clsx from "clsx"
import Link from "next/link"
import { useRouter } from "next/router"
import { FC } from "react"
import Image from "next/image"
import { ImageLoader } from "@lib/util/loader"

const BlockRender: FC<{ block: BlocksContentProp; amount: number }> = ({
    block,
    amount
}) => {
    const { push } = useRouter()
    return (
        <div
            key={`${block?.id}-blockitem`}
            onClick={(e) =>
                block?.wholeClick ? push(block?.cta?.ctaUrl ?? "") : null
            }
            className={clsx(
                "flex w-full justify-center relative max-h-[25vh]",
                { "cursor-pointer": block?.wholeClick }
            )}
        >
            <div
                className={clsx("relative md:h-[25vh] flex", {
                    "aspect-video h-[unset] w-full 2xl:w-[unset]": amount > 1,
                    "w-full h-[100px] md:h-[150px] xl:h-[200px]": amount === 1
                })}
            >
                <Link passHref href={block?.cta?.ctaUrl ?? ""} prefetch={false}>
                    <a
                        className={clsx({
                            "z-20 absolute text-lg font-display subpixel-antialiased left-0 right-0 bottom-0 px-4 py-3 text-white backdrop-filter backdrop-blur-md backdrop-brightness-75 overflow-hidden":
                                amount > 1,
                            "z-20 text-sm sm:text-md md:text-1xl lg:text-2xl xl:text-3xl 2xl:text-4xl mb-2 self-center text-right sm:text-center pr-10 sm:pr-0 w-full font-display subpixel-antialiased":
                                amount === 1
                        })}
                    >
                        {block?.cta?.ctaText ?? ""}
                    </a>
                </Link>
                {block?.image?.url && (
                    <Image
                        loader={ImageLoader}
                        src={block?.image?.url ?? ""}
                        layout={"fill"}
                        objectFit={amount > 1 ? "cover" : "contain"}
                        alt={block?.image?.alternativeText ?? ""}
                        sizes={
                            amount > 1
                                ? "(max-width: 384px) 100vw, (max-width: 500px) 100vw, (max-width: 640px) 100vw, 33vw"
                                : "(max-width: 1920px) 100vw, 1920px"
                        }
                    />
                )}
            </div>
        </div>
    )
}

export default BlockRender
